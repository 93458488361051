<template>
	<div style="height: 100%">
		<div style="padding: 20px; height: 800px">
			<!-- 搜素框 -->
			<el-form
				:inline="true"
				:model="params"
				style="
					padding-top: 20px;
					padding-left: 20px;
					margin-bottom: 20px;
					background-color: #fff;
					border-radius: 8px;
				"
				size="small"
			>
				<el-form-item label="账号">
					<el-input v-model="params.account" placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="params.userName" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号">
					<el-input v-model="params.phone" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="身份证号">
					<el-input v-model="params.idCard" placeholder="请输身份证号"></el-input>
				</el-form-item>
				<el-form-item label="电工证编号">
					<el-input v-model="params.electricianLicense" placeholder="请输入电工证编号"></el-input>
				</el-form-item>
				<el-form-item label="通讯地址">
					<el-input v-model="params.address" placeholder="请输入通讯地址"></el-input>
				</el-form-item>
				<el-form-item label="所属区域" prop="regionId">
					<el-select v-model="params.regionId" placeholder="请选择">
						<el-option
							v-for="item in regionList"
							:key="item.id"
							:label="item.regionName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色">
					<el-select v-model="params.roleId" placeholder="请选择">
						<el-option label="全部" value=""></el-option>
						<el-option
							v-for="item in roleList"
							:key="item.id"
							:label="item.roleName"
							:value="item.id"
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="checkData" size="mini">查询</el-button>
					<el-button type="info" @click="reset" size="mini">重置</el-button>
				</el-form-item>
			</el-form>
			<!-- 表格 -->
			<div style="height: 82%; background-color: #fff; border-radius: 8px; padding-top: 8px">
				<el-dropdown
					v-show="authorization('/home-admin/routineManage/user-list/export')"
					class="btn-right"
					@command="exportExcel"
				>
					<el-button type="info" size="small"
						>导出
						<i class="el-icon-arrow-down el-icon--right"></i>
					</el-button>
					<el-dropdown-menu slot="dropdown">
						<!-- <el-dropdown-item @click="exportExcel(0)">导出选择行</el-dropdown-item> -->
						<el-dropdown-item command="exportExcel" :type="1">导出所有</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<el-button
					type="primary"
					size="small"
					style="float: right; margin-top: 10px; margin-right: 20px; margin-bottom: 10px"
					@click="add()"
					v-show="authorization('/home-admin/routineManage/user-list/add')"
					>新增</el-button
				>
				<el-table
					:data="tableData"
					stripe
					height="80%"
					style="margin-left: 20px; width: 98%"
					size="small"
					:header-cell-style="{
						'background-color': '#84aace4a',
						color: '#343333',
						'text-align': 'center',
					}"
					:cell-style="{ 'text-align': 'center' }"
				>
					<el-table-column prop="account" label="账号" width="120px"></el-table-column>
					<el-table-column prop="userName" label="姓名" width="120px"></el-table-column>
					<el-table-column prop="phone" label="手机号" width="100"></el-table-column>
					<el-table-column
						prop="address"
						label="通讯地址"
						:show-overflow-tooltip="true"
						width="200"
					></el-table-column>
					<el-table-column
						prop="idCard"
						label="身份证"
						:show-overflow-tooltip="true"
						width="150"
					></el-table-column>
					<el-table-column
						prop="quitStatus"
						label="在职状态"
						:show-overflow-tooltip="true"
						:formatter="quitStatusFormat"
					></el-table-column>
					<el-table-column
						prop="inductionTime"
						label="入职时间"
						:show-overflow-tooltip="true"
						:formatter="(row) => dateFormat(row.inductionTime)"
						width="100"
					></el-table-column>
					<el-table-column
						prop="quitTime"
						label="离职时间"
						:show-overflow-tooltip="true"
						:formatter="(row) => dateFormat(row.quitTime)"
						width="100"
					></el-table-column>
					<el-table-column
						prop="roleId"
						label="角色"
						:formatter="roleFormat"
						:show-overflow-tooltip="true"
						width="150"
					></el-table-column>
					<el-table-column
						prop="regionManageList"
						label="所属区域"
						:formatter="regionFormat"
						:show-overflow-tooltip="true"
						width="100"
					></el-table-column>
					<el-table-column
						prop="receivingOrder"
						label="接单状态"
						:formatter="receivingOrderFormat"
					></el-table-column>
					<el-table-column
						prop="status"
						label="账号状态"
						:formatter="statusFormat"
					></el-table-column>
					<el-table-column
						prop="loginTime"
						label="最近登录时间"
						:formatter="(row) => dateTimeFormat(row.loginTime)"
						width="140px"
					></el-table-column>
					<el-table-column label="操作" fixed="right" width="150">
						<template slot-scope="scope">
							<el-tooltip
								effect="dark"
								content="编辑"
								placement="top-end"
								v-show="authorization('/home-admin/routineManage/user-list/edit')"
							>
								<el-button
									type="primary"
									icon="el-icon-edit"
									circle
									size="mini"
									@click="edit(scope.row)"
									style="margin-right: 5px"
								></el-button>
							</el-tooltip>
							<el-tooltip
								effect="dark"
								content="删除"
								placement="top-end"
								v-show="authorization('/home-admin/routineManage/user-list/del')"
							>
								<el-popconfirm title="确认删除吗？" @confirm="deleteUser(scope.row)">
									<el-button
										content="删除"
										slot="reference"
										type="danger"
										icon="el-icon-delete"
										circle
										size="mini"
									></el-button>
								</el-popconfirm>
							</el-tooltip>
							<el-tooltip effect="dark" content="详情" placement="top-end">
								<el-button
									type="info"
									icon="el-icon-tickets"
									circle
									size="mini"
									@click="details(scope.row)"
									style="margin-left: 5px"
									v-show="authorization('/home-admin/routineManage/userEntry-manage/detail')"
								></el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div
					style="
						padding-top: 20px;
						height: 40px;
						background-color: #fff;
						width: 100%;
						border-radius: 8px;
						text-align: right;
					"
				>
					<el-pagination
						background
						:page-sizes="sizes"
						layout="total,sizes,prev,pager,next"
						:total="total"
						@current-change="handleCurrentChange"
						@size-change="sizeChange"
					></el-pagination>
				</div>
			</div>
			<!-- 弹出层 -->
			<el-dialog
				title="新增"
				:visible.sync="dialogFormAdd"
				width="700px"
				:close-on-click-modal="false"
				top="10px"
			>
				<UserAdd
					:data="userArray"
					ref="userAdd"
					@sureClick="addSubMit"
					@cancleClick="dialogFormAdd = false"
				/>
			</el-dialog>
			<!-- 编辑弹出层 -->
			<el-dialog
				title="编辑"
				:visible.sync="dialogFormEdit"
				width="700px"
				:close-on-click-modal="false"
				top="10px"
			>
				<UserAdd
					:data="userArray"
					ref="userAdd"
					@sureClick="editSubMit"
					@cancleClick="dialogFormEdit = false"
				/>
			</el-dialog>

			<el-dialog
				title="人员信息详情"
				:visible.sync="dialogShowDetail"
				width="800px"
				:close-on-click-modal="false"
				top="10px"
			>
				<el-descriptions
					:column="2"
					border
					size="mini"
					:labelStyle="{ width: '15%' }"
					:contentStyle="{ width: '35%' }"
					style="
						background-color: #fff;
						padding: 20px;
						padding-bottom: 0;
						border-radius: 8px;
						border-top-left-radius: 0%;
						border-top-right-radius: 0%;
					"
				>
					<el-descriptions-item label="姓名">{{ detailRow.userName }}</el-descriptions-item>
					<el-descriptions-item label="手机号码">{{ detailRow.phone }}</el-descriptions-item>
					<el-descriptions-item label="身份证号">{{ detailRow.idCard }}</el-descriptions-item>
					<el-descriptions-item label="年龄">{{ detailRow.age }}</el-descriptions-item>
					<el-descriptions-item label="性别">{{ detailRow.sex }}</el-descriptions-item>
					<el-descriptions-item label="电工证">{{
						detailRow.electricianLicense
					}}</el-descriptions-item>
					<el-descriptions-item label="电工证到期日">{{
						dateFormat(detailRow.electricianExpDate)
					}}</el-descriptions-item>
					<el-descriptions-item label="现居住地址">{{ detailRow.address }}</el-descriptions-item>
				</el-descriptions>
				<el-descriptions
					direction="vertical"
					:column="3"
					border
					size="mini"
					style="
						background-color: #fff;
						padding-left: 20px;
						padding-left: 20px;
						padding-top: 5px;
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					"
				>
					<el-descriptions-item
						label="身份证人像面照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
					>
						<el-image
							:src="transformUrl(detailRow.idCardFrontImageUrl)"
							style="height: 200px"
							v-show="detailRow.idCardFrontImageUrl !== null && detailRow.idCardFrontImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.idCardFrontImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item
						label="身份证国徽面照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
					>
						<el-image
							:src="transformUrl(detailRow.idCardBackImageUrl)"
							style="height: 200px"
							v-show="detailRow.idCardBackImageUrl !== null && detailRow.idCardBackImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.idCardBackImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item
						label="电工证照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
					>
						<el-image
							:src="transformUrl(detailRow.electricianImageUrl)"
							style="height: 200px"
							v-show="detailRow.electricianImageUrl !== null && detailRow.electricianImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.electricianImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item
						label="银行卡照片"
						labelStyle="width: 25%;"
						contentStyle="height:200px"
					>
						<el-image
							:src="transformUrl(detailRow.bankCardImageUrl)"
							style="height: 200px"
							v-show="detailRow.bankCardImageUrl !== null && detailRow.bankCardImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.bankCardImageUrl))"
						></el-image>
					</el-descriptions-item>
					<el-descriptions-item label="自拍照" labelStyle="width: 25%;" contentStyle="height:200px">
						<el-image
							:src="transformUrl(detailRow.selfImageUrl)"
							style="height: 200px"
							v-show="detailRow.selfImageUrl !== null && detailRow.selfImageUrl != ''"
							:preview-src-list="new Array(1).fill(transformUrl(detailRow.selfImageUrl))"
						></el-image>
					</el-descriptions-item>
				</el-descriptions>
				<el-descriptions
					direction="vertical"
					:column="3"
					border
					size="mini"
					style="
						background-color: #fff;
						padding: 20px;
						padding-top: 5px;
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					"
					><el-descriptions-item
						label="其他照片"
						labelStyle="width: 100%;"
						contentStyle="height:200px"
					>
						<div>
							<el-image
								v-for="(item, index) in otherImgList"
								:key="index"
								:src="item"
								:preview-src-list="otherImgList"
								style="height: 200px; width: 25%; margin-right: 5px; float: left"
							></el-image>
						</div>
					</el-descriptions-item>
				</el-descriptions>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { transformUrl, dateTimeFormat, dateFormat } from '../common/format';
	import { authorization } from '../../../authorization/authorization';
	import UserAdd from '../routineManage/UserAdd.vue';
	export default {
		components: { UserAdd },
		name: 'UserEntry',
		data() {
			return {
				roleList: [],
				regionList: [],
				checkList: [],
				dialogFormAdd: false,
				dialogFormEdit: false,
				dialogShowDetail: false,
				tableData: [],
				departmentList: [],
				choiceList: [],
				curriculumList: [],
				classList: [],
				otherImgList: '',
				params: {
					account: '',
					userName: '',
					phone: '',
					page: 1,
					size: 10,
				},
				detailRow: {},
				userArray: {
					id: '',
					account: '',
					password: '',
					userName: '',
					phone: '',
					imgUrl: '',
					roleIdList: [],
					status: '',
					openId: '',
					regionIdList: [],
					regionIds: '',
					address: '',
					inductionTime: null,
					quitTime: null,
					idCard: '',
					quitStatus: '',
					receivingOrder: '',
					commFileList: [],
					commFilePath: [],
					age: '',
					sex: '',
					electricianExpDate: '',
					electricianLicense: '',
					idCardFrontImageList: [],
					idCardFrontImageUrl: '',
					idCardBackImageList: [],
					idCardBackImageUrl: '',
					electricianImageList: [],
					electricianImageUrl: '',
					bankCardImageList: [],
					bankCardImageUrl: '',
					selfImageList: [],
					selfImageUrl: '',
				},
				total: 0,
				sizes: [10, 15],
			};
		},
		created() {
			this.querySysRoleList();
			this.queryRegionList();
			this.queryUserList();
		},
		methods: {
			authorization,
			transformUrl,
			dateFormat,
			dateTimeFormat,
			queryUserList() {
				//获取用户列表
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/list-user',
					params: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
					this.total = res.data.data.total;
				});
			},
			querySysRoleList() {
				//获取角色下拉数据
				this.axios({
					method: 'get',
					url: '/v1/web/sys-role/select-sys-role',
					params: this.params,
				}).then((res) => {
					this.roleList = res.data.data.list;
				});
			},
			queryRegionList() {
				//获取区域下拉数据
				this.axios({
					method: 'get',
					url: '/v1/web/region-manage/select-region-drop-down',
					params: this.params,
				}).then((res) => {
					this.regionList = res.data.data.list;
				});
			},
			add() {
				//新增弹出层
				this.userArray = {
					id: '',
					account: '',
					password: '',
					userName: '',
					phone: '',
					imgUrl: null,
					roleIdList: [],
					status: '',
					openId: '',
					regionIdList: [],
					regionIds: '',
					address: '',
					inductionTime: null,
					quitTime: null,
					idCard: '',
					quitStatus: '',
					receivingOrder: '',
					commFileList: [],
					commFilePath: [],
					age: '',
					sex: '',
					electricianExpDate: '',
					electricianLicense: '',
					idCardFrontImageList: [],
					idCardFrontImageUrl: null,
					idCardBackImageList: [],
					idCardBackImageUrl: null,
					electricianImageList: [],
					electricianImageUrl: null,
					bankCardImageList: [],
					bankCardImageUrl: null,
					selfImageList: [],
					selfImageUrl: null,
				};
				this.dialogFormAdd = true;
			},
			addSubMit() {
				this.userArray = this.$refs.userAdd.userArray;
				this.axios({
					method: 'post',
					url: '/v1/web/user-info/add-user',
					data: this.userArray,
				}).then((res) => {
					if (res.data.code === 200) {
						this.dialogFormAdd = false;
						this.$message({
							message: '添加成功',
							type: 'success',
						});
						this.queryUserList();
					} else {
						this.$message.error(res.data.message);
					}
				});
			},
			edit(row) {
				//编辑弹出层
				this.userArray = { ...row };
				this.userArray.roleIdList = [];
				if (row.roleList.length > 0) {
					for (let i = 0; i < row.roleList.length; i++) {
						this.userArray.roleIdList.push(Number(row.roleList[i].id));
					}
				}

				this.userArray.regionIdList = [];
				if (row.regionManageList.length > 0) {
					for (let i = 0; i < row.regionManageList.length; i++) {
						this.userArray.regionIdList.push(Number(row.regionManageList[i].id));
					}
				}
				this.dialogFormEdit = true;
			},
			editSubMit() {
				//编辑提交
				this.userArray = this.$refs.userAdd.userArray;
				this.axios({
					method: 'put',
					url: '/v1/web/user-info/update-user/' + this.userArray.id,
					data: this.userArray,
				}).then((res) => {
					if (res.data.code === 200) {
						this.dialogFormEdit = false;
						this.$message({ message: '修改成功', type: 'success' });
						this.queryUserList();
					} else {
						this.$message.error(res.data.message);
					}
				});
			},
			deleteUser(row) {
				//删除
				this.axios({
					method: 'delete',
					url: '/v1/web/user-info/delete-user/' + row.id,
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.queryUserList();
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
			exportExcel(type) {
				// if (type === 0) {
				//   console.log("选中");
				// } else {
				console.log(type);
				//}
				this.axios({
					method: 'post',
					url: '/v1/web/user-info/export',
					data: {},
					responseType: 'blob',
				}).then((res) => {
					let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
					let name = Date.now() + '.xlsx';
					if (window.navigator && window.navigator.msSaveOrOpenBlob) {
						//IE
						window.navigator.msSaveOrOpenBlob(blob, name);
					} else {
						let objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
						let downFile = document.createElement('a');
						downFile.style.display = 'none';
						downFile.href = objectUrl;
						downFile.download = name;
						document.body.appendChild(downFile);
						downFile.click();
						document.body.removeChild(downFile);
						window.URL.revokeObjectURL(objectUrl);
					}
				});
			},
			details(row) {
				this.detailRow = { ...row };
				let otherImgs = [];
				if (this.detailRow.imgUrl !== null) {
					otherImgs = this.detailRow.imgUrl.split(',');
				}

				this.otherImgList = [];
				for (let i = 0; i < otherImgs.length; i++) {
					let url = this.transformUrl(otherImgs[i]);
					if (url != '') {
						this.otherImgList.push(url);
					}
				}

				this.dialogShowDetail = true;
			},
			quitStatusFormat: function (row) {
				if (row.quitStatus === 1) {
					return '在职';
				} else if (row.quitStatus === 2) {
					return '离职';
				}
			},
			regionFormat: function (row) {
				if (row.regionManageList.length > 0) {
					let resultName = '';
					for (let i = 0; i < row.regionManageList.length; i++) {
						if (i > 0) {
							resultName = resultName + ',';
						}
						resultName = resultName + row.regionManageList[i].regionName;
					}
					return resultName;
				}
				return '';
			},
			roleFormat: function (row) {
				if (row.roleList.length > 0) {
					let resultName = '';
					for (let i = 0; i < row.roleList.length; i++) {
						if (i > 0) {
							resultName = resultName + ',';
						}
						resultName = resultName + row.roleList[i].roleName;
					}
					return resultName;
				}
				return '';
			},
			receivingOrderFormat: function (row) {
				if (row.receivingOrder === 0) {
					return '正常接单';
				} else if (row.receivingOrder === 1) {
					return '暂不接单';
				}
			},
			statusFormat: function (row) {
				if (row.status === 0) {
					return '正常登录';
				} else if (row.status === 1) {
					return '禁止登录';
				}
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryUserList();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryUserList();
			},
			checkData() {
				this.queryUserList();
			},
			reset() {
				this.params = { page: 1, size: 10 };
				this.queryUserList();
			},
		},
	};
</script>

<style scoped>
	.avatar-uploader-icon {
		border: 1px dashed #d9d9d9;
		font-size: 28px;
		color: #8c939d;
		width: 120px;
		height: 120px;
		line-height: 120px;
		text-align: center;
	}
	.avatar {
		width: 120px;
		height: 120px;
		display: block;
	}
	.btn-right {
		float: right;
		margin-top: 10px;
		margin-right: 20px;
		margin-bottom: 10px;
	}
</style>
